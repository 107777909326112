import * as Sentry from "@sentry/nuxt";

const config = useRuntimeConfig();


// function getSentryIntegrations() {
//     // don't load on server
//     if (!import.meta.client) return [];
//     // console.log('Sentry', Sentry.browserTracingIntegration())

//     const router = useRouter();
//     const browserTracing =  Sentry.browserTracingIntegration({
//         router
//     });

//     const sessionReplay =  Sentry.Replay({
//         maskAllText: false,
//         blockAllMedia: false,
//     });

    
//     return [browserTracing, sessionReplay];
// }


Sentry.init({
    enabled:config.public.SENTRY_ENABLED === 'true',
    // If set up, you can use your runtime config here
    // dsn: useRuntimeConfig().public.sentry.dsn,
    dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
    // integrations: getSentryIntegrations(),
    //   dsn: "https://ac9b2961ea331c1e2319a668dc76f748@o4505271521705984.ingest.us.sentry.io/4507775597412352",

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // This sets the sample rate. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
});